import './skip-link-focus-fix';
import 'script-loader!slick-carousel';
import 'script-loader!slick-lightbox';

(function($) {

	$(document).ready(function() {
		menuToggle();
		searchToggle();
		accordionShortcode();
		inputEffects();
		faqsBlock();
		propertyImagesSlider();
		propertyMessage();

		$(document).on('facetwp-refresh', function() {
			$('.facetwp-template').animate({ opacity: 0 }, 100);
		});
		$(document).on('facetwp-loaded', function() {
			$('.facetwp-template').animate({ opacity: 1 }, 100);

			// if (FWP.loaded) {
			// 	$('html, body').animate({
			// 		scrollTop: $('.properties-archive').offset().top - $('#masthead').outerHeight() - 60
			// 	}, 500);
			// }
		});
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Header search toggle
	function searchToggle() {
		var header = $('#masthead');

		$("#search-toggle").on('click', function() {
			header.toggleClass('site-header--search-open');
			$("#header-search").focus();
		});

		$("#search-toggle-mobile").on('click', function() {
			header.toggleClass('site-header--search-open');
			$("#header-search").focus();
		});

		$('body').on('click', function(event) {
			if( !$(event.target).is('#search-toggle, #header-search, #search-toggle-mobile') ) {
				header.removeClass('site-header--search-open');
			}
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__title').on('click', function() {
			var accordion = $(this).parent('.accordion-shortcode'),
				content   = $(this).next('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Form input label effect
	function inputEffects() {
		$(".site-footer .ginput_container input[type=text], .site-footer .ginput_container input[type=email], .site-footer .ginput_container input[type=number], .site-footer .ginput_container textarea, .property-details .ginput_container input[type=text],.property-details .ginput_container input[type=email], .property-details .ginput_container input[type=number], .property-details .ginput_container textarea").focus(function(){
			var label = $(this).parent().prev('.gfield_label');
			label.addClass('gfield_label--focus');
		}).blur(function(){
			if(!$(this).val()) {
				var label = $(this).parent().prev('.gfield_label');
				label.removeClass('gfield_label--focus');
			}
		})

		$(document).bind('gform_post_render', function(){
			inputEffects();
			$(".site-footer .ginput_container input[type=text], .site-footer .ginput_container input[type=email], .site-footer .ginput_container input[type=number], .site-footer .ginput_container textarea, .property-details .ginput_container input[type=text],.property-details .ginput_container input[type=email], .property-details .ginput_container input[type=number], .property-details .ginput_container textarea").each(function() {
				if($(this).val()) {
					var label = $(this).parent().prev('.gfield_label');
					label.addClass('gfield_label--focus');
				}
			});
		});
	}


	// FAQs block
	function faqsBlock() {
		$(".faqs-block__heading").on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faqs-block__faq'),
			    content = faq.find('.faqs-block__content');
				
			content.slideToggle(300);
			faq.toggleClass('faqs-block__faq--open');

			$('.faqs-block__content').not(content).slideUp(300);
			$('.faqs-block__faq').not(faq).removeClass('faqs-block__faq--open');
		});
	}

	// Single property images slider
	function propertyImagesSlider() {
		var slider    = $('#property-slider'),
			sliderNav = $('#property-slider-nav');

		slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			speed: 300,
		});

		sliderNav.slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '#property-slider',
			focusOnSelect: true,
			arrows: false,
			draggable: false,
		});

		slider.on('afterChange', function(event, slick, currentSlide) {
			sliderNav.slick('slickGoTo', currentSlide);
			var currrentNavSlideElem = sliderNav.find('.slick-slide[data-slick-index="' + currentSlide + '"]');
			sliderNav.find('.slick-slide.is-active').removeClass('is-active');
			$(currrentNavSlideElem).addClass('is-active');
		});

		$('#property-slider-next').on('click', function() {
			slider.slick('slickNext');
		});

		$('#property-slider-previous').on('click', function() {
			slider.slick('slickPrev');
		});

		$('#property-nav-next').on('click', function() {
			sliderNav.slick('slickNext');
		});

		$('#property-nav-previous').on('click', function() {
			sliderNav.slick('slickPrev');
		});

		slider.slickLightbox({
			itemSelector: 'div a',
		});
	}

	// Property contact message text
	function propertyMessage() {
		var property = $('.page-header__title').text(),
		    message  = 'I would like more information about ' + property,
		    textarea = $('.gfield.property-message textarea');

		textarea.html(message);
	}


})(jQuery);